import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Security, useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import StoreProvider from './store';
import Loading from './components/Core/loading';
import configuration from './configuration';
import AuthRoute from './components/Login/auth_route';
import { AuthProvider } from './context/auth_context'; 

const MainLayout = lazy(() => import('./components/Layout/Layout'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const Loader = lazy(() => import('./components/Core/loading'));
const LoginCallback = lazy(() => import('./components/Login/login_callback'));
const Onboarding =lazy(() => import('./components/Onboarding/Onboarding'));
const Login = lazy(() => import('./components/Login/Login'));
const ProductsLayout = lazy(() => import('./components/Layout/products_layout'));
const AddProduct = lazy(() => import('./components/Products/AddProduct'));
const EditProduct = lazy(() => import('./components/Products/EditProduct'));
const Category = lazy(() => import('./components/Category/Category'));
const AddCategory = lazy(() => import('./components/Category/AddCategory'));
const EditCategory = lazy(() => import('./components/Category/EditCategory'));
const Supplier = lazy(() => import('./components/Suppliers/Supplier'));
const AddSupplier = lazy(() => import('./components/Suppliers/AddSupplier'));
const EditSupplier = lazy(() => import('./components/Suppliers/EditSupplier'));
const Warehouse = lazy(() => import('./components/Warehouses/Warehouse'));
const AddWarehouse = lazy(() => import('./components/Warehouses/AddWarehouse'));
const EditWarehouse = lazy(() => import('./components/Warehouses/EditWarehouse'));
const PoLayout = lazy(() => import('./components/Layout/po_layout'));
const AddPurchaseOrder = lazy(() => import('./components/PurchaseOrder/AddPurchaseOrder'));
const EditPurchaseOrderWrapper = lazy(() => import('./components/PurchaseOrder/EditPurchaseOrderWrapper'));


function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={configuration} restoreOriginalUri={restoreOriginalUri}>
      <Suspense fallback={<Loading />}>
        <AuthProvider>
          <StoreProvider>
            <Routes>
              <Route path="/" element={<Onboarding />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/callback" element={<LoginCallback />} />

              {/* Authenticated routes under "/" */}
              <Route path="/" element={<AuthRoute element={<MainLayout />} />}>
              <Route path="products" element={<AuthRoute element={<ProductsLayout />} />} />
              <Route path="products/addproduct" element={<AuthRoute element={<AddProduct />} />}/>
                <Route path="products/editproduct/:productId" element={<AuthRoute element={<EditProduct />} />}/> 
                <Route path="dashboard" element={<AuthRoute element={<Loader />} />} />
                <Route path="directory" element={< AuthRoute element={<Category />}/>} />
                <Route path="directory/category" element={<AuthRoute element={<Category/>} />} />
                <Route path="directory/category/addcategory" element={<AuthRoute element={<AddCategory />} />}/>
                <Route path="directory/category/editcategory/:categoryId" element={<AuthRoute element={<EditCategory />} />}/> 
                <Route path="warehouse" element={<AuthRoute element={<Warehouse />} />} />
                <Route path="warehouse/addwarehouse" element={<AuthRoute element={<AddWarehouse />} />}/>
                <Route path="warehouse/editwarehouse/:warehouseId" element={<AuthRoute element={<EditWarehouse />} />}/> 
                <Route path="directory/supplier" element={<AuthRoute element={<Supplier />} />} />
                <Route path="directory/supplier/addsupplier" element={<AuthRoute element={<AddSupplier />} />}/>
                <Route path="directory/supplier/editsupplier/:supplierId" element={<AuthRoute element={<EditSupplier />} />}/> 
                <Route path="purchaseorder" element={<AuthRoute element={<PoLayout/>} />} />
                <Route path="purchaseorder/addpurchaseorder" element={<AuthRoute element={<AddPurchaseOrder />} />}/>
                <Route path="purchaseorder/editpurchaseorder/:purchaseOrderId" element={<AuthRoute element={<EditPurchaseOrderWrapper />} />}/> 
                <Route path="payment" element={<AuthRoute element={<Loader />}/>} />
                <Route path="roles" element={<AuthRoute element={<Loader />} />} />
                <Route path="support" element={<AuthRoute element={<Loader />}/>} />
                <Route path="settings" element={<AuthRoute element={<Loader />}/>} />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Routes>
            </StoreProvider>
        </AuthProvider>
      </Suspense>
    </Security>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

// Loading.tsx
import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <div className="spinner">Loading...</div> {/* Customize this as needed */}
    </div>
  );
};

export default Loading;

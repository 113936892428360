// constants
export const date_format = "YYYY-MM-DD";
export const date_display_format = " Do MMM YYYY";
export const time_format = "HH:mm:ss";
export const ts_format = `${date_format} ${time_format}`;

// Literal Constants
export const start_date = "start_date";
export const start_time = "start_time";
export const end_date = "end_date";
export const end_time = "end_time";

// Sidebar item names
export const Dashboard = "Dashboard";
export const Products = "Products";

// Sidebar item keys
export const dashboard = "dashboard";
export const products = "products";


export const MenuItemPaths = {
    [Dashboard]: "/dashboard",
}


// LocalStorage constants
export const SELECTED_HEADER = 'SELECTED_HEADER';
